import { render, staticRenderFns } from "./TextSection.vue?vue&type=template&id=3b0edbe6&scoped=true&"
import script from "./TextSection.vue?vue&type=script&setup=true&lang=js&"
export * from "./TextSection.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./TextSection.vue?vue&type=style&index=0&id=3b0edbe6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b0edbe6",
  null
  
)

export default component.exports