<script setup>
import { defineProps } from 'vue'
import { getSettingValueInPropsComputed } from '@/modules/sites-section/site-store-section/pages/composables/useSection'
import BaseSection from './BaseSection.vue'

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
})

const title = getSettingValueInPropsComputed(props, 'title')
const subTitle = getSettingValueInPropsComputed(props, 'sub_title')
const text = getSettingValueInPropsComputed(props, 'text')
</script>

<template>
  <base-section
    :section="section"
  >
    <div class="a-text-section">
      <div class="container">
        <div
          v-if="title"
          class="a-text-section__title h2 mb-2"
        >
          {{ title }}
        </div>
        <div
          v-if="subTitle"
          class="a-text-section__sub-title h4 mb-2"
        >
          {{ subTitle }}
        </div>
        <div
          v-if="text"
          class="a-text-section__text"
        >
          {{ text }}
        </div>
      </div>
    </div>
  </base-section>
</template>

<style scoped lang="scss">
.a-text-section{
  padding: 20px;
  background: #fff;
  &__text{
    white-space: pre-line;
  }
}
</style>
